export const useUserStatesStore = defineStore('userStates', () => {
    const CurrentMenu = ref('personal_info')
    const UserEmail = ref('');
    const UserPhone = ref('');
    const FullName = ref('');
    const DateOfBirth = ref('');
    const Gender = ref(false);
    const Role = ref('CUSTOMER');
    const SelectedSpecifications = ref<any[]>([]);

    const CurrentSpecificationsGroup = ref({
        id: 144,
        name: "IT Services & Freelance & SEO",
        cat: [
            {
                "id": 145,
                "name": "Working with text and data"
            },
            {
                "id": 146,
                "name": "Communication services"
            },
            {
                "id": 147,
                "name": "WEB design and site development"
            },
            {
                "id": 148,
                "name": "Cyber security"
            },
            {
                "id": 149,
                "name": "Big data"
            },
            {
                "id": 150,
                "name": "Marketing"
            },
            {
                "id": 151,
                "name": "SEO & social networks"
            },
            {
                "id": 152,
                "name": "Mobile application development"
            },
            {
                "id": 153,
                "name": "Programming"
            },
            {
                "id": 154,
                "name": "Multiple PC help"
            },
            {
                "id": 155,
                "name": "Data recovery"
            },
            {
                "id": 156,
                "name": "Photo, video and audio services"
            },
            {
                "id": 157,
                "name": "Others"
            }
        ]
    },)

    function useClearUserStates() {
        CurrentMenu.value = 'personal_info'
        FullName.value = ''
        UserEmail.value = ''
        UserPhone.value = ''
        DateOfBirth.value = ''
        Gender.value = false
        Role.value = 'CUSTOMER'
        SelectedSpecifications.value = []

    }

    return {
        UserEmail,
        UserPhone,
        FullName,
        DateOfBirth,
        CurrentMenu,
        Gender,
        Role,
        SelectedSpecifications,
        CurrentSpecificationsGroup,

        useClearUserStates,

    }
})